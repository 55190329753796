import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { IonicStorageModule } from "@ionic/storage";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { LocationAccuracy } from "@ionic-native/location-accuracy/ngx";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient } from "@angular/common/http";
import { EventEmitterService } from "./services/event-emitter.service";
import { SharedPipesModule } from "./pipes/shared-pipes.module";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
// import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Facebook, FacebookLoginResponse } from "@ionic-native/facebook/ngx";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { NativeKeyboard } from "@ionic-native/native-keyboard/ngx";
import { Clipboard } from "@ionic-native/clipboard/ngx";
import { ErrorHandler } from "./services/ErrorHandler";

const config: SocketIoConfig = {
  url: "https://overbookingapp.com:8443",
  options: {},
};

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "./assets/i18n/addStructure/", suffix: ".json" },
    { prefix: "./assets/i18n/util/", suffix: ".json" },
    { prefix: "./assets/i18n/overbookingsList/", suffix: ".json" },
    { prefix: "./assets/i18n/sendOverbooking/", suffix: ".json" },
    { prefix: "./assets/i18n/profile/", suffix: ".json" },
    { prefix: "./assets/i18n/auth/", suffix: ".json" },
  ]);
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: "ios" }),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedPipesModule,
    MatGoogleMapsAutocompleteModule.forRoot(),
    SocketIoModule.forRoot(config),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [],
  providers: [
    StatusBar,
    SplashScreen,
    AndroidPermissions,
    Geolocation,
    LocationAccuracy,
    OneSignal,
    EventEmitterService,
    InAppBrowser,
    AppVersion,
    NativeKeyboard,
    Clipboard,
    // Keyboard,
    Facebook,
    ErrorHandler,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getIncomingRangeWidth'
})
export class GetIncomingRangeWidthPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value === 0){
      return 0;
    }

    let incoming = args[0];
    let outcoming = args[1];
    let max = Number(incoming) + Number(outcoming);
    let total = 100*value/max;
    let percentage = total.toFixed(2) + "%";

    return percentage;
  }
}

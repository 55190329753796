import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BackButtonService {
  private mustCloseUrls = ['/login','/home/tabs/overbookings-list','/home/tabs/accounting','/home/tabs/overbooking-send-choice-structure','/home/tabs/notifications','/home/tabs/profile'];
  private mustDoNothingUrls = ['/register-fill'];
  
  constructor() {}

  ngOnInit() {}

  mustCloseApp(currentUrl : string) : boolean {
    if (this.mustCloseUrls.includes(currentUrl)) {
      return true;
    }
    return false;
  }

  mustDoNothing(currentUrl : string) : boolean {
    if (this.mustDoNothingUrls.includes(currentUrl)) {
      return true;
    }
    return false;
  }
}

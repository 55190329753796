import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getSegmentTitle'
})
export class GetSegmentTitlePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    switch(value){
      case 1:
      return "inviate";
      case 2:
      return "ricevute";
      case 4:
      return "assegnate";
      case 6:
      return "archiviate";
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class DebugService {
  
  constructor(
    private http: HttpClient,
    private util : UtilsService
  ) {}

  alert( page : string, error : string ){
    return this.http.get<any>(this.util.baseUrl + 'debug/alert', { params : {
      page: page,
      error: error
    } });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateQuarter'
})

export class TranslateQuarterPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    switch(value){
      case 1:
      return "FIRST_QUARTER"
      case 2:
      return "SECOND_QUARTER"
      case 3:
      return "THIRD_QUARTER"
      case 4:
      return "FOURTH_QUARTER"
    }
  }
}

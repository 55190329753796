import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getAccountingTotal'
})
export class GetAccountingTotalPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value > 0){
      return "+" + value.toFixed(2);
    }

    return value.toFixed(2);
  }
}

import { Injectable } from "@angular/core";
import {
  ToastController,
  LoadingController,
  AlertController,
  PickerController,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Facebook } from "@ionic-native/facebook/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { Platform } from "@ionic/angular";
import { Overbooking } from "../models/Overbooking";
import { Overbooking2 } from "../models/Overbooking2";
import * as moment from "moment";

import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  public baseUrl = environment.apiUrl;
  public loading: HTMLIonLoadingElement;
  public intSelected: number = 0;
  public decimalSelected: number = 0;
  public isSendingOverbooking: boolean = false;

  constructor(
    public toastController: ToastController,
    public loadingController: LoadingController,
    public alertController: AlertController,
    public translate: TranslateService,
    private pickerCtrl: PickerController,
    private iab: InAppBrowser,
    private platform: Platform,
    public facebook: Facebook
  ) {
    translate.setDefaultLang("it");
  }

  formatDateYearMonthDay(date: any) {
    return moment(date).format("YYYY-MM-DD");
  }

  async presentRankingPicker(callback?: any) {
    const picker = await this.pickerCtrl.create({
      buttons: [
        {
          text: "Inserisci",
          handler: (pickerInserted) => {
            callback(pickerInserted);
          },
        },
      ],
      columns: [
        {
          name: "integer",
          selectedIndex: this.intSelected,
          options: [
            {
              text: "5",
              value: 5,
            },
            {
              text: "6",
              value: 6,
            },
            {
              text: "7",
              value: 7,
            },
            {
              text: "8",
              value: 8,
            },
            {
              text: "9",
              value: 9,
            },
          ],
        },
        {
          name: "decimal",
          selectedIndex: this.decimalSelected,
          options: [
            {
              text: "0",
              value: 0,
            },
            {
              text: "1",
              value: 1,
            },
            {
              text: "2",
              value: 2,
            },
            {
              text: "3",
              value: 3,
            },
            {
              text: "4",
              value: 4,
            },
            {
              text: "5",
              value: 5,
            },
            {
              text: "6",
              value: 6,
            },
            {
              text: "7",
              value: 7,
            },
            {
              text: "8",
              value: 8,
            },
            {
              text: "9",
              value: 9,
            },
          ],
        },
      ],
    });
    await picker.present();
  }

  async presentEmailToUserPrompt(callback?: any) {
    const alert = await this.alertController.create({
      header: "Inserisci la mail dell'ospite",
      inputs: [
        {
          name: "email",
          placeholder: "email ospite",
        },
      ],
      buttons: [
        {
          text: "Annulla",
          role: "cancel",
        },
        {
          text: "Invia",
          handler: (inputInserted) => {
            if (inputInserted.email != "") {
              callback(inputInserted.email);
            }
          },
        },
      ],
    });
    await alert.present();
  }

  async presentToastWithOptions(
    position: any,
    header: string,
    content: string,
    duration: number
  ) {
    const toast = await this.toastController.create({
      header: header,
      message: content,
      position: position,
      duration: duration,
      color: "secondary",
      buttons: [
        {
          text: this.translate.instant("CLOSE"),
          role: "cancel",
          handler: () => {
            toast.dismiss();
          },
        },
      ],
    });
    toast.present();
  }

  async presentLoading(duration: number) {
    const loading = await this.loadingController.create({
      spinner: null,
      duration: duration,
      message: "Please wait...",
      translucent: true,
      cssClass: "custom-class custom-loading",
    });
    return await loading.present();
  }

  async presentAlert(title: string, content: string) {
    const alert = await this.alertController.create({
      header: this.translate.instant(title),
      subHeader: "",
      message: this.translate.instant(content),
      buttons: ["OK"],
    });

    return await alert.present();
  }

  async presentNoConnectionAlert() {
    const alert = await this.alertController.create({
      message: this.translate.instant("NO_CONNECTION"),
      buttons: ["OK"],
    });

    return await alert.present();
  }

  async presentSomethingWrongAlert() {
    const alert = await this.alertController.create({
      message: this.translate.instant("OPS_SOMETHING_WRONG"),
      buttons: ["OK"],
    });

    return await alert.present();
  }

  async presentAlertAndWaitAccept(
    title: string,
    content: string,
    callback?: any
  ) {
    let message = "";
    if (content != "") {
      message = this.translate.instant(content);
    }
    const alert = await this.alertController.create({
      header: this.translate.instant(title),
      subHeader: "",
      message: this.translate.instant(message),
      buttons: [
        {
          text: this.translate.instant("OK"),
          handler: () => {
            if (callback) {
              callback(true);
            }
          },
        },
      ],
    });

    return await alert.present();
  }

  async presentAlertWithCustomChoices(
    title: string,
    content: string,
    textButton1?: string,
    textButton2?: string,
    callback?: any
  ) {
    if (textButton1 == "") textButton1 = this.translate.instant("OK");
    if (textButton2 == "") textButton2 = this.translate.instant("CANCEL");
    let message = "";
    if (content != "") {
      message = this.translate.instant(content);
    }
    const alert = await this.alertController.create({
      header: this.translate.instant(title),
      subHeader: "",
      message: message,
      buttons: [
        {
          text: textButton1,
          handler: () => {
            if (callback) {
              callback(true);
            }
          },
        },
        {
          text: textButton2,
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            if (callback) {
              callback(false);
            }
          },
        },
      ],
    });

    await alert.present();
  }

  async presentPrivacyCheck(title: string, content: string, callback?: any) {
    let message = "";
    if (content != "") {
      message = this.translate.instant(content);
    }
    const alert = await this.alertController.create({
      header: this.translate.instant(title),
      subHeader: "",
      message: this.translate.instant(message),
      buttons: [
        {
          text: this.translate.instant("SHOW_POLICY"),
          handler: () => {
            if (callback) {
              callback(true);
            }
          },
        },
      ],
    });

    return await alert.present();
  }

  async presentDeleteAccountAlert(title: string, content: string, callback) {
    const alert = await this.alertController.create({
      header: this.translate.instant(title),
      subHeader: "",
      message: this.translate.instant(content),
      buttons: [
        {
          text: "Conferma",
          handler: () => {
            if (callback) {
              callback(true);
            }
          },
        },
        {
          text: "Annulla",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            if (callback) {
              callback(false);
            }
          },
        },
      ],
    });

    return await alert.present();
  }

  calcExpirationTime(time: Date) {
    let expirationDate = moment(time).add(12, "hours");

    if (expirationDate <= moment(new Date())) {
      return false;
    }

    return moment(expirationDate).fromNow(true);
  }

  mapOverbooking(ob: Overbooking): any {
    let self = this;

    let totalPrice =
      Number(ob.singlePrice) +
      Number(ob.dusPrice) +
      Number(ob.twinPrice) +
      Number(ob.doublePrice) +
      Number(ob.triplePrice) +
      Number(ob.quadruplePrice) +
      Number(ob.apartmentPrice);

    return {
      id: ob.id_richiesta,
      structureId: ob.ownStructureId,
      id_user: ob.id_user,
      id_user_assegnatario: ob.id_user_assegnatario,
      requestStructure: ob.requestingStructureName,
      availableStructures: ob.availablesStructures,
      reachedStructures: ob.numero_strutture_raggiunte,
      requestTime: ob.orario_richiesta,
      expirationTime: self.calcExpirationTime(ob.orario_richiesta),
      ownStructureName: ob.ownStructure,
      ownStructureId: ob.ownStructureId,
      assignedStructureId: ob.assignedStructureId,
      assignedStructureName: ob.assignedStructureName,
      noShowGuest: ob.cliente_non_presentato,
      type: ob.tipo,
      state: ob.stato,
      archived: ob.archived,
      checkin: moment(ob.checkin).format("YYYY-MM-DD"),
      checkout: moment(ob.checkout).format("YYYY-MM-DD"),
      pax: ob.numero_persone,
      rooms: ob.totalRooms,
      single: ob.camere_singole,
      singlePrice: ob.singlePrice,
      dus: ob.dus,
      dusPrice: ob.dusPrice,
      twin: ob.camere_doppie,
      twinPrice: ob.twinPrice,
      double: ob.camere_matrimoniali,
      doublePrice: ob.doublePrice,
      triple: ob.camere_triple,
      triplePrice: ob.triplePrice,
      quadruple: ob.camere_quadruple,
      quadruplePrice: ob.quadruplePrice,
      apartaments: ob.appartamenti,
      apartamentPrice: ob.apartmentPrice,
      totalPrice: totalPrice,
      quotationSinglePrice: ob.quotationSinglePrice,
      quotationDusPrice: ob.quotationDusPrice,
      quotationTwinPrice: ob.quotationTwinPrice,
      quotationDoublePrice: ob.quotationDoublePrice,
      quotationTriplePrice: ob.quotationTriplePrice,
      quotationQuadruplePrice: ob.quotationQuadruplePrice,
      quotationApartmentPrice: ob.quotationApartmentPrice,
      quotationTotalPrice: ob.quotationTotalPrice,
      availabilityNotes: ob.availabilityNotes,
      budget: ob.budget_notte,
      hasPrice: ob.hasPrice == 1,
      nights: ob.numero_notti,
      breakfast: ob.breakfast,
      notRefundable: ob.nonrimborsabile,
      radius: ob.raggio,
      code: ob.codice + "",
      guestFullname: ob.nome_cliente,
      guestEmail: ob.email_cliente,
      guestPhone: ob.tel_cliente,
      seen: ob.seen == 1,
      notes: ob.note_richiesta,
    };
  }

  mapOverbookings(list: Array<Overbooking>): Array<Overbooking2> {
    let self = this;

    return list.map(function (ob) {
      let totalPrice =
        Number(ob.singlePrice) +
        Number(ob.dusPrice) +
        Number(ob.twinPrice) +
        Number(ob.doublePrice) +
        Number(ob.triplePrice) +
        Number(ob.quadruplePrice) +
        Number(ob.apartmentPrice);

      return {
        id: ob.id_richiesta,
        structureId: ob.ownStructureId,
        city_id: ob.city_id,
        id_user: ob.id_user,
        id_user_assegnatario: ob.id_user_assegnatario,
        requestStructureId: ob.requestingStructureId,
        requestStructure: ob.requestingStructureName,
        availableStructures: ob.availablesStructures,
        assignedAvailabilityNotes: ob.assignedAvailabilityNotes,
        reachedStructures: ob.numero_strutture_raggiunte,
        requestTime: ob.orario_richiesta,
        expirationTime: self.calcExpirationTime(ob.orario_richiesta),
        ownStructureName: ob.ownStructure,
        ownStructureId: ob.ownStructureId,
        assignedStructureId: ob.assignedStructureId,
        assignedStructureName: ob.assignedStructureName,
        noShowGuest: ob.cliente_non_presentato,
        type: ob.tipo,
        state: ob.stato,
        archived: ob.archived,
        checkin: moment(ob.checkin).format("YYYY-MM-DD"),
        checkout: moment(ob.checkout).format("YYYY-MM-DD"),
        pax: ob.numero_persone,
        rooms: ob.totalRooms,
        single: ob.camere_singole,
        singlePrice: ob.singlePrice,
        dus: ob.dus,
        dusPrice: ob.dusPrice,
        twin: ob.camere_doppie,
        twinPrice: ob.twinPrice,
        double: ob.camere_matrimoniali,
        doublePrice: ob.doublePrice,
        triple: ob.camere_triple,
        triplePrice: ob.triplePrice,
        quadruple: ob.camere_quadruple,
        quadruplePrice: ob.quadruplePrice,
        apartaments: ob.appartamenti,
        apartamentPrice: ob.apartmentPrice,
        totalPrice: totalPrice,
        quotationSinglePrice: ob.quotationSinglePrice,
        quotationDusPrice: ob.quotationDusPrice,
        quotationTwinPrice: ob.quotationTwinPrice,
        quotationDoublePrice: ob.quotationDoublePrice,
        quotationTriplePrice: ob.quotationTriplePrice,
        quotationQuadruplePrice: ob.quotationQuadruplePrice,
        quotationApartmentPrice: ob.quotationApartmentPrice,
        quotationTotalPrice: ob.quotationTotalPrice,
        budget: ob.budget_notte,
        hasPrice: ob.hasPrice == 1,
        nights: ob.numero_notti,
        breakfast: ob.breakfast,
        notRefundable: ob.nonrimborsabile,
        radius: ob.raggio,
        code: ob.codice + "",
        guestFullname: ob.nome_cliente,
        guestEmail: ob.email_cliente,
        guestPhone: ob.tel_cliente,
        seen: ob.seen == 1,
        notes: ob.note_richiesta,
        availabilityNotes: ob.availabilityNotes,
      };
    });
  }

  async startLoading(title: string, message: string) {
    this.loading = await this.loadingController.create({
      spinner: null,
      message:
        `
        <div class="custom-spinner-container">
        <div class="custom-spinner-box">` +
        title +
        `</div>
        <div class="custom-spinner-box">` +
        message +
        `</div>
        </div>`,
      translucent: true,
      cssClass: "custom-class custom-loading",
    });
    return await this.loading.present();
  }

  async endLoading() {
    this.loading.dismiss();
  }

  async showVersionAlert() {
    const alert = await this.alertController.create({
      // header: this.translate.instant('FORCED_UPDATE'),
      header:
        "Stai utilizzando una versione non aggiornata dell'app. Aggiorna subito!",
      subHeader: "",
      message: "",
      backdropDismiss: false,
      buttons: [
        {
          // text: this.translate.instant('GO_STORE'),
          text: "Vai allo store",
          handler: () => {
            if (this.platform.is("android")) {
              this.iab.create(
                "https://play.google.com/store/apps/details?id=com.overbooking.app",
                "_system",
                "location=no,hidden=no,toolbarposition=top"
              );
            } else if (this.platform.is("ios")) {
              this.iab.create(
                "https://apps.apple.com/it/app/overbooking-app/id1482888549",
                "_system",
                "location=no,hidden=no,toolbarposition=top"
              );
            }
            navigator["app"].exitApp();
          },
        },
      ],
    });

    alert.present();
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'translateMonth'
})
export class TranslateMonthPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let month = moment(value, 'MM').format('MMMM');
    month = month.charAt(0).toUpperCase() + month.slice(1);
    return month;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatCheckinCheckout'
})
export class FormatCheckinCheckoutPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return moment(value.checkin).format('DD MMM') + " - " + moment(value.checkout).format('DD MMM')
  }

}

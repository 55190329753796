import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { TranslateMonthPipe } from './translate-month.pipe';
import { TranslateStructureTypePipe } from './translate-structure-type.pipe';
import { ConvertDistanceReadableFormPipe } from './convert-distance-readable-form.pipe';
import { GetAccountingTotalPipe } from './get-accounting-total.pipe';
import { GetIncomingRangeWidthPipe } from './get-incoming-range-width.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { FormatCheckinCheckoutPipe } from './format-checkin-checkout.pipe';
import { FormatRequestTimePipe } from './format-request-time.pipe';
import { GetSegmentTitlePipe } from './get-segment-title.pipe';
import { FormatCreditCardExpirationPipe } from './format-credit-card-expiration.pipe';
import { TranslateQuarterPipe } from './translate-quarter.pipe';
import { FormatAccountingMonthPipe } from './format-accounting-month.pipe';

@NgModule({
  imports: [
    CommonModule, IonicModule
  ],
  declarations: [TranslateMonthPipe, TranslateStructureTypePipe, GetAccountingTotalPipe, GetIncomingRangeWidthPipe,ConvertDistanceReadableFormPipe, FormatDatePipe, FormatCheckinCheckoutPipe, FormatRequestTimePipe, GetSegmentTitlePipe, FormatCreditCardExpirationPipe, TranslateQuarterPipe, FormatAccountingMonthPipe],
  exports: [TranslateMonthPipe, TranslateQuarterPipe, TranslateStructureTypePipe ,GetAccountingTotalPipe, GetIncomingRangeWidthPipe,ConvertDistanceReadableFormPipe,FormatDatePipe, FormatCheckinCheckoutPipe, FormatRequestTimePipe, GetSegmentTitlePipe,FormatCreditCardExpirationPipe, FormatAccountingMonthPipe]
})

export class SharedPipesModule { }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertDistanceReadableForm'
})
export class ConvertDistanceReadableFormPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return Number(value).toFixed(2);
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';
import { DebugService } from './debug.service';
import { throwError} from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHandler {
  constructor(
    private utils : UtilsService,
    private debugService : DebugService,
    private router : Router
  ) {}

  errorHandler( error: HttpErrorResponse) {
    if (error.status === 0) {
      this.utils.presentNoConnectionAlert();
      return;
    }

    if (error.status === 500) {
        this.debugService.alert( this.router.url, error.error ).subscribe();
        this.utils.presentSomethingWrongAlert();
    }

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}, ` +
        `and the message was: ${error.error.text}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(
      error
    );
  };
}

import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'check', pathMatch: 'full' },
  { path: 'check', loadChildren:'./pages/check/check.module#CheckPageModule'},
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule'},
  { path: 'register', loadChildren: './pages/register/register.module#RegisterPageModule' },
  { path: 'passrecover', loadChildren: './pages/passrecover/passrecover.module#PassrecoverPageModule' },
  { path: 'home', loadChildren: './pages/tabs/tabs.module#TabsPageModule',canActivate: [AuthGuard]},
  { path: 'overbooking', loadChildren: './pages/overbooking-details/overbooking-details.module#OverbookingDetailsPageModule'},
  { path: 'overbooking-send', loadChildren: './pages/overbooking-send/overbooking-send.module#OverbookingSendPageModule' },
  { path: 'registercity', loadChildren: './pages/registercity/registercity.module#RegistercityPageModule' },
  { path: 'registerstructure', loadChildren: './pages/registerstructure/registerstructure.module#RegisterstructurePageModule' },
  { path: 'profile', loadChildren: './pages/profile/profile.module#ProfilePageModule' },
  { path: 'accounting', loadChildren: './pages/accounting/accounting.module#AccountingPageModule' },
  { path: 'account-settings/:id', loadChildren: './pages/account-settings/account-settings.module#AccountSettingsPageModule' },
  { path: 'profile-info', loadChildren: './pages/profile-info/profile-info.module#ProfileInfoPageModule' },
  { path: 'overbooking-send-filter', loadChildren: './pages/overbooking-send-filter/overbooking-send-filter.module#OverbookingSendFilterPageModule' },
  { path: 'overbooking-send-recap', loadChildren: './pages/overbooking-send-recap/overbooking-send-recap.module#OverbookingSendRecapPageModule' },
  { path: 'structure-address/:id', loadChildren: './pages/structure-address/structure-address.module#StructureAddressPageModule' },
  { path: 'structure-type/:id', loadChildren: './pages/structure-type/structure-type.module#StructureTypePageModule' },
  { path: 'overbooking-send-choice-structure', loadChildren: './pages/overbooking-send-choice-structure/overbooking-send-choice-structure.module#OverbookingSendChoiceStructurePageModule' },
  { path: 'notifications', loadChildren: './pages/notifications/notifications.module#NotificationsPageModule' },
  { path: 'profile-personal', loadChildren: './pages/profile-personal/profile-personal.module#ProfilePersonalPageModule' },
  { path: 'profile-bank/:id', loadChildren: './pages/profile-bank/profile-bank.module#ProfileBankPageModule' },
  { path: 'profile-structures', loadChildren: './pages/profile-structures/profile-structures.module#ProfileStructuresPageModule' },
  { path: 'profile-about-overbooking', loadChildren: './pages/profile-about-overbooking/profile-about-overbooking.module#ProfileAboutOverbookingPageModule' },
  { path: 'profile-privacy', loadChildren: './pages/profile-privacy/profile-privacy.module#ProfilePrivacyPageModule' },
  { path: 'profile-terms-conditions', loadChildren: './pages/profile-terms-conditions/profile-terms-conditions.module#ProfileTermsConditionsPageModule' },
  { path: 'overbooking-rooms', loadChildren: './pages/overbooking-rooms/overbooking-rooms.module#OverbookingRoomsPageModule' },
  { path: 'register-fill', loadChildren: './pages/register-fill/register-fill.module#RegisterFillPageModule' },
  { path: 'edit-structure/:id', loadChildren: './pages/edit-structure/edit-structure.module#EditStructurePageModule' },
  { path: 'edit-structure-info/:id', loadChildren: './pages/edit-structure-info/edit-structure-info.module#EditStructureInfoPageModule' },
  { path: 'edit-structure-ranking/:id', loadChildren: './pages/edit-structure-ranking/edit-structure-ranking.module#EditStructureRankingPageModule' },
  { path: 'edit-structure-amenities/:id', loadChildren: './pages/edit-structure-amenities/edit-structure-amenities.module#EditStructureAmenitiesPageModule' },
  { path: 'edit-structure-bank/:id', loadChildren: './pages/edit-structure-bank/edit-structure-bank.module#EditStructureBankPageModule' },
  { path: 'overbooking-details-assign/:id/:ids', loadChildren: './pages/overbooking-details-assign/overbooking-details-assign.module#OverbookingDetailsAssignPageModule' },
  { path: 'overbooking-details-availables/:id', loadChildren: './pages/overbooking-details-availables/overbooking-details-availables.module#OverbookingDetailsAvailablesPageModule' },
  { path: 'overbooking-quotations', loadChildren: './pages/overbooking-quotations/overbooking-quotations.module#OverbookingQuotationsPageModule' },
  { path: 'overbooking-details-notes', loadChildren: './pages/overbooking-details-notes/overbooking-details-notes.module#OverbookingDetailsNotesPageModule' },
  { path: 'user/choose/:id', loadChildren: () => import('./pages/user-choose/user-choose.module').then( m => m.UserChoosePageModule)},
  { path: 'overbooking-details-user-assign/:id', loadChildren: () => import('./pages/overbooking-details-user-assign/overbooking-details-user-assign.module').then( m => m.OverbookingDetailsAssignPageModule)},
  { path: 'accounting-structure-details', loadChildren: './pages/accounting-structure-details/accounting-structure-details.module#AccountingStructureDetailsPageModule' },
  { path: 'profile-referral/:id', loadChildren: './pages/profile-referral/profile-referral.module#ProfileReferralPageModule' },
  { path: 'monitor-socket', loadChildren: './pages/monitor-socket/monitor-socket.module#MonitorSocketPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }

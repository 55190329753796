import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {
  public updateOverbookingsList = new EventEmitter();
  public airbnbStarsUpdated = new EventEmitter();
  public airbnbStarsSet = new EventEmitter();
  public hotelStarsUpdated = new EventEmitter();
  public showOverbookingSendedToast = new EventEmitter();
  public updateFilledWizard = new EventEmitter();
  public updateStructuresList = new EventEmitter();
  public updateNotifications = new EventEmitter();
  public reloadNotifications = new EventEmitter();
  public reloadOverbookingDetail = new EventEmitter();
  public updateOverbookingsAndNotifications = new EventEmitter();
  public changeOverbookingsType = new EventEmitter();

  constructor() {}

  reloadOverbookingsList() {
    this.updateOverbookingsList.emit();
  }

  updateAirbnbStars(stars : number){
    this.airbnbStarsUpdated.emit(stars);
  }

  setAirbnbStars(stars : number){
    this.airbnbStarsSet.emit(stars);
  }

  updateHotelStars(stars : number){
    this.hotelStarsUpdated.emit(stars);
  }

  overbookingSended(reachedStructures : number){
    this.showOverbookingSendedToast.emit(reachedStructures);
  }

  updateFilled(){
    this.updateFilledWizard.emit();
  }

  //Chiamato quando viene modificata una propria struttura. Suscribe da : overbooking-send-choice-structure, overbookings-list, profile-structures.
  updateStructures(){
    this.updateStructuresList.emit();
  }

  updateNotificationsList(){
    this.updateNotifications.emit();
  }

  subscribeAgainNotifications(){
    this.reloadNotifications.emit();
  }

  updateOverbookingsType(overbookingType : number){
    this.changeOverbookingsType.emit(overbookingType);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translateStructureType'
})

export class TranslateStructureTypePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let type = "";

    switch (Number(value)) {
      case 1:
      type = "Hotel";
      break;
      case 2:
      type = "B&B";
      break;
      case 3:
      type = "Guest House";
      break;
      case 4:
      type = "CAV";
      break;
    }

    return type;
  }
}

import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject , Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { User } from '../models/User';
import { Router} from '@angular/router';
import { UtilsService } from './utils.service';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from './ErrorHandler';
import qs from 'qs';

const TOKEN_KEY = 'auth-token';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/x-www-form-urlencoded'
  })
};

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  // Attraverso gli Observables si può fare il subscribe per incanalare il flusso dell'applicazione.
  // è un concetto molto simile a Flux e Redux.
  public authenticationState = new BehaviorSubject(false);
  public user: User;
  public tempUser: User;
  public loginFacebook = false;

  constructor(
    private storage: Storage,
    private http: HttpClient,
    private util: UtilsService,
    private errorHandler: ErrorHandler,
    private router: Router) {
    this.checkToken();
  }

  checkToken() {
    this.storage.get(TOKEN_KEY).then((response) => {
      this.user = response;
      if (response) {
        this.authenticationState.next(true);
        this.router.navigate(['home/tabs/overbookings-list']);
      } else {
        this.authenticationState.next(false);
        this.router.navigate(['login']);
      }
    });
  }

  async storeUser(user: User) {
    await this.storage.set(TOKEN_KEY, user);
    this.user = user;
    this.authenticationState.next(true);
  }

  login(user: User): Observable<User> {
    return this.http.post<User>(this.util.baseUrl + 'user/login', qs.stringify(user), httpOptions)
    .pipe(
      catchError((error) => this.errorHandler.errorHandler(error))
    );
  }

  register(user: User): Observable<User> {
    return this.http.post<User>(this.util.baseUrl + 'user', qs.stringify(user), httpOptions)
    .pipe(
      catchError((error) => this.errorHandler.errorHandler(error))
    );
  }

  recPass(form: object) {
    return this.http.post<any>(this.util.baseUrl + 'user/password/recover', qs.stringify(form), httpOptions)
    .pipe(
      catchError((error) => this.errorHandler.errorHandler(error))
    );
  }

  checkUserMail(mail: string) {
    return this.http.get<any>(this.util.baseUrl + 'user/email/' + mail)
    .pipe(
      catchError((error) => this.errorHandler.errorHandler(error))
    );
  }

  checkUserMailAndPromoCode(email: string, shareCode: string) {
    return this.http.get<any>(this.util.baseUrl + 'user/emailPromoCode', { params : {
            email,
            shareCode
          } })
    .pipe(
      catchError((error) => this.errorHandler.errorHandler(error))
    );
  }

  async logout() {
    await this.storage.remove(TOKEN_KEY);
    this.authenticationState.next(false);
    await this.router.navigate(['login'], {replaceUrl : true});
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }
}

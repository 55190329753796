import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatRequestTime'
})
export class FormatRequestTimePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return moment(value).format('DD/MM') + " alle " +  moment(value).format('HH:mm')
  }

}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public auth: AuthenticationService,private router: Router) {}

  canActivate(): boolean {

    if(!this.auth.isAuthenticated()){
      this.router.navigate(['login']);
      return false;
    }

    return true;
  }
}

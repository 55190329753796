import { Component, ViewChild } from "@angular/core";
import { Platform, IonRouterOutlet } from "@ionic/angular";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { FormGroup } from "@angular/forms";
import * as moment from "moment";
import { Router } from "@angular/router";
import { BackButtonService } from "./services/back-button.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  public myForm: FormGroup;
  public submitted: boolean;
  public locationCoords: any;

  @ViewChild(IonRouterOutlet, { static: false }) routerOutlet: IonRouterOutlet;

  constructor(
    private platform: Platform,
    private router: Router,
    private statusBar: StatusBar,
    private backButtonService: BackButtonService
  ) {
    this.initializeApp();

    this.platform.backButton.subscribeWithPriority(9999, () => {
      if (this.backButtonService.mustCloseApp(this.router.url)) {
        navigator["app"].exitApp();
      } else if (this.backButtonService.mustDoNothing(this.router.url)) {
        // Do nothing
      } else if (this.routerOutlet && this.routerOutlet.canGoBack()) {
        this.routerOutlet.pop();
      }
    });
  }

  ngOnInit() {}

  initializeApp() {
    console.log("****** initializeApp *******");
    this.platform.ready().then(() => {
      moment.locale("it");

      if (this.platform.is("android")) {
        this.statusBar.overlaysWebView(true);
        this.statusBar.styleBlackOpaque();
      }

      if (
        this.platform.is("ios") ||
        this.platform.is("ipad") ||
        this.platform.is("iphone")
      ) {
        this.statusBar.backgroundColorByHexString("000");
      }
    });
  }
}
